//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    separator: {
      type: Boolean,
      default: true
    },
    return: {
      type: String,
      default: null
    },
    campaignSource: {
      type: String,
      default: null,
    },
    campaignRef: {
      type: String,
      default: null,
    },
    showLogin: {
      type: Boolean,
      default: false,
    }
  },
  data () {
    return {
      usagePurpose: null,
    }
  },
  methods: {
    selectUsagePurpose (usagePurpose) {
      this.usagePurpose = usagePurpose
      this.$gtm.push({
        event: 'usage_purpose_chosen',
        usage_purpose: usagePurpose,
      })
      this.$ph.capture('usage_purpose_chosen', {
        usage_purpose: usagePurpose,
      })
    }
  }
}
