export const meta = ({ title, description, ogImage, ogImageText, canonical, ogType = 'website', noindex }) => {
  title = title ?? 'Docue'

  const meta = [
    { hid: 'og:type', property: 'og:type', content: ogType },
    { hid: 'og:title', property: 'og:title', content: title }
  ]

  if (description) {
    meta.push({ hid: 'description', name: 'description', content: description })
    meta.push({ hid: 'og:description', property: 'og:description', content: description })
  }

  if (noindex) {
    meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
  }

  /**
   * Build ogImage
   * 1. If ogImageText provided, generate dynamic ogImage
   * 2. If ogImage provided, use directly that.
   */

  if (ogImageText) {
    meta.push({ hid: 'og:image', property: 'og:image', content: `https://ogimage.docue.com/${encodeURIComponent(ogImageText)}.png` })
  } else if (ogImage) {
    meta.push({ hid: 'og:image', property: 'og:image', content: ogImage })
  }

  const link = []
  if (canonical) {
    link.push({ hid: 'canonical', rel: 'canonical', href: canonical })
  }

  return {
    title,
    meta,
    link
  }
}
