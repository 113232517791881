//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    countries () {
      return this.$country.all.filter(c => !!c.countryCode)
    }
  },
  methods: {
    changeCountry (e) {
      location.href = this.$country.path('/', e.target.value)
    }
  }
}
