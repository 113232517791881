//
//
//

import { COUNTRIES, COUNTRY_GLOBAL } from '~/utils/constants'
export default {
  head () {
    // Add hreflang tags to country index
    // to tell google about the region variants
    return {
      link: Object.values(COUNTRIES)
        .filter(country => country.code !== this.$country.current.code && country.code !== COUNTRY_GLOBAL)
        .map(country => ({ rel: 'alternative', href: `https://${this.$config.siteUrl}/${country.code}`, hreflang: country.code }))
    }
  }
}
