//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    open: {
      type: Boolean,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      openedItemId: null,
    }
  },
  computed: {
    activeItems () {
      if (!this.openedItemId) {
        return this.items
      }

      const openedItemIdx = this.items.findIndex(x => x.id === this.openedItemId)
      const openedItem = this.items[openedItemIdx]

      const res = [...this.items]
      const dropdownItem = { isDropdown: true, id: `${openedItem.id}.sub`, subItems: openedItem.subnav_items }
      res.splice(openedItemIdx + 1, 0, dropdownItem)

      return res
    },
  },
  watch: {
    open (open) {
      const bodyEl = document.querySelector('body')
      if (open) {
        bodyEl.classList.add('tw-overflow-hidden')
      } else {
        bodyEl.classList.remove('tw-overflow-hidden')
      }

      if (open) {
        this.openedItemId = null

        setTimeout(() => {
          if (this.$refs.navShadow) {
            this.$refs.navShadow.update()
          }
        })
      }
    },
  },
  methods: {
    close () {
      this.$emit('update:open', false)
    },

    toggleNavItem (id) {
      if (!this.openedItemId) {
        this.openedItemId = id
      } else {
        this.openedItemId = null
      }

      setTimeout(() => {
        if (this.$refs.navShadow) {
          this.$refs.navShadow.update()
        }
      })
    },
  },
}
