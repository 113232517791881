var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"container",staticClass:"tw-relative tw-leading-tight"},[(!_vm.subnavItems.length)?_c('CustomLinkV2',{staticClass:"!tw-text-sm tw-whitespace-nowrap !tw-font-normal !tw-py-1",class:[_vm.light ? 'header-nav-link-plain-white' : 'header-nav-link'],attrs:{"url":_vm.item.url,"variant":_vm.light ? 'plain-white' : 'plain',"size":"xs"}},[_c('span',{staticClass:"tw-hidden xl:tw-inline"},[_vm._v(_vm._s(_vm.item.title))]),_vm._v(" "),_c('span',{staticClass:"xl:tw-hidden"},[_vm._v(_vm._s(_vm.shortTitle))])]):_c('div',{staticClass:"tw-relative"},[_c('DTDropdown',{attrs:{"align-body":"left","menu-class":"!tw-w-96 tw-max-w-xs"},on:{"update:open":_vm.handleDTriggerToggle},scopedSlots:_vm._u([{key:"button",fn:function(ref){
var toggle = ref.toggle;
var open = ref.open;
return [_c('DTButton',{ref:"dTrigger",staticClass:"!tw-text-sm !tw-font-normal !tw-py-1",class:_vm.light ? '!tw-text-white' : null,attrs:{"variant":_vm.light ? 'plain-white' : 'plain',"size":"xs"},on:{"click":function($event){return _vm.handleDTriggerEvent($event, toggle, open)},"mouseover":function($event){return _vm.handleDTriggerEvent($event, toggle, open)},"mouseout":function($event){return _vm.handleDTriggerEvent($event, toggle, open)}}},[_c('span',{staticClass:"tw-hidden xl:tw-inline"},[_vm._v(_vm._s(_vm.item.title))]),_vm._v(" "),_c('span',{staticClass:"xl:tw-hidden"},[_vm._v(_vm._s(_vm.shortTitle))]),_vm._v(" "),_c('SolidChevronDownIcon',{staticClass:"tw-w-4",class:_vm.light ? 'tw-text-white' : 'tw-text-gray-400'})],1)]}}])},[_vm._v(" "),_c('div',{ref:"dropdown",staticStyle:{"margin-top":"-15px","padding-top":"15px"}},_vm._l((_vm.subnavItems),function(ref){
var id = ref.id;
var title = ref.title;
var url = ref.url;
var description = ref.description;
var icon = ref.icon;
return _c('DTDropdownItem',{key:id,attrs:{"href":url,"rel":url[0] !== '/' ? 'noopener noreferrer' : null},scopedSlots:_vm._u([(icon)?{key:"icon",fn:function(){return [(icon)?_c('NuxtImg',{attrs:{"src":icon.filename,"alt":icon.alt,"width":16,"height":16}}):_vm._e()]},proxy:true}:null],null,true)},[_vm._v(" "),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-text-gray-700"},[_vm._v("\n              "+_vm._s(title)+"\n            ")]),_vm._v(" "),(description)?_c('div',{staticClass:"tw-text-xs tw-text-gray-400"},[_vm._v("\n              "+_vm._s(description)+"\n            ")]):_vm._e()])])}),1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }