//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    value: {
      handler (value) {
        if (!process.client) { return }

        value
          ? document.body.classList.add('prevent-scroll')
          : document.body.classList.remove('prevent-scroll')
      },
      immediate: true
    }
  },
  beforeDestroy () {
    document.body.classList.remove('prevent-scroll')
  },
  methods: {
    close () {
      this.$emit('input', false)
    }
  }
}
