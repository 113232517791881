//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
  },
  methods: {
    hide () {
      this.$emit('input', false)
    }
  }
}
