//
//
//
//
//
//
//
//
//
//

// Scroll shadows can also be implemented with CSS: https://codepen.io/chriscoyier/pen/YzXBYvL
// But this only works when the content doesn't have a background, otherwise it will go over the shadows. This JS implementation is relatively simple and works around that.

export default {
  props: {
    innerProps: {
      type: Object,
      required: false,
      default: null,
    },
    shadowStyle: {
      type: String,
      required: false,
      default: 'black',
    },
  },

  data () {
    return {
      showTopBlur: false,
      showBottomBlur: false,
    }
  },

  computed: {
    shadowColor1 () {
      return this.shadowStyle === 'white' ? 'rgba(255,255,255,0.95)' : 'rgba(0,0,0,0.15)'
    },

    shadowColor2 () {
      return this.shadowStyle === 'white' ? 'rgba(255,255,255,0.65) 70%' : 'rgba(0,0,0,0.05)'
    },

    shadowHeightClass () {
      return this.shadowStyle === 'white' ? 'tw-h-10' : 'tw-h-5'
    },
  },

  mounted () {
    // Note: We could also listen to "resize" events if we want this to work correctly when resizing the viewport.

    // This gets cleared when the element unmounts, so no need for explicit cleanup.
    this.$refs.scrollContainer.addEventListener('scroll', this.handleScroll)

    // Just hard-code a very small delay so that the child elements have time to mount. Vue seems to have no nice way to set a deterministic callback for a child element's mounting.
    setTimeout(() => {
      this.handleScroll()
    }, 10)
  },

  methods: {
    handleScroll () {
      const scrollBuffer = 15

      const scrollEl = this.$refs?.scrollContainer

      // Indirect way to check whether our component has been unmounted right before this was fired, avoid crashing if that happens.
      if (!scrollEl) {
        return
      }

      this.showTopBlur = scrollEl.scrollTop > scrollBuffer
      this.showBottomBlur = scrollEl.scrollTop + scrollEl.offsetHeight < scrollEl.scrollHeight - scrollBuffer
    },

    // Can be called from parent
    update () {
      this.handleScroll()
    },
  },
}
