import { render, staticRenderFns } from "./HeaderSection.vue?vue&type=template&id=5c58d712&"
import script from "./HeaderSection.vue?vue&type=script&lang=js&"
export * from "./HeaderSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SignupModal: require('/builds/docue/website/components/SignupModal.vue').default,HeaderSkipToContent: require('/builds/docue/website/components/Header/HeaderSkipToContent.vue').default,CustomLinkV2: require('/builds/docue/website/components/CustomLinkV2.vue').default,HeaderCountrySelector: require('/builds/docue/website/components/Header/HeaderCountrySelector.vue').default,IconDocue: require('/builds/docue/website/components/Icon/IconDocue.vue').default,IconSearchMd: require('/builds/docue/website/components/Icon/IconSearchMd.vue').default,DTButton: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTButton/DTButton.vue').default,OutlineMenuIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/outline/src/components/MenuIcon.vue').default,HeaderNavLink: require('/builds/docue/website/components/Header/HeaderNavLink.vue').default,HeaderMobileMenu: require('/builds/docue/website/components/Header/HeaderMobileMenu.vue').default})
