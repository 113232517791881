import { render, staticRenderFns } from "./HeaderNavLink.vue?vue&type=template&id=186cb67f&"
import script from "./HeaderNavLink.vue?vue&type=script&lang=js&"
export * from "./HeaderNavLink.vue?vue&type=script&lang=js&"
import style0 from "./HeaderNavLink.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CustomLinkV2: require('/builds/docue/website/components/CustomLinkV2.vue').default,SolidChevronDownIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/solid/src/components/ChevronDownIcon.vue').default,DTButton: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTButton/DTButton.vue').default,DTDropdownItem: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTDropdown/DTDropdownItem.vue').default,DTDropdown: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTDropdown/DTDropdown.vue').default})
