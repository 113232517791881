//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { COUNTRY_FLAG_IMGS } from '~/utils/runtimeConstants'
export default {
  props: {
    buttonClass: {
      type: String,
      required: false,
      default: ''
    },
    buttonVariant: {
      type: String,
      default: 'plain'
    },
    flagClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    countries () {
      return this.$country.all.filter(c => !!c.countryCode)
    }
  },
  methods: {
    changeCountry (country) {
      location.href = this.$country.path('/', country.code)
    },
    flag (country) {
      return COUNTRY_FLAG_IMGS[country.countryCode]
    },
  },
}
