//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    light: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return { dTriggerClicked: false, menuOpen: false, dropdownMouseOutHandler: null }
  },
  computed: {
    shortTitle () {
      return this.item.shortTitle || this.item.title
    },
    subnavItems () {
      return this.item.subnav_items
    },
  },
  methods: {
    handleDTriggerEvent (evt, toggle, open) {
      // Toggling by click
      // First click before hover doesn't close.
      if (evt.type === 'click' && (!open || this.dTriggerClicked)) {
        toggle()

        this.dTriggerClicked = true

        return
      }

      // Set this here if we didn't run the previous click handlers.
      if (evt.type === 'click') {
        this.dTriggerClicked = true
      }

      // Opening by hover
      if (evt.type === 'mouseover' && !open) {
        toggle()

        // The menu auto-closes on mouse leave when opened through hover
        // Setup the menu's listener here since this context has access to the `toggle` function.
        if (!this.dropdownMouseOutHandler) {
          this.dropdownMouseOutHandler = (e) => {
            // Note that we don't use `open` as that may be outdated when the handler fires.
            if (this.menuOpen && !this.dTriggerClicked && !this.$refs.dTrigger.$el.contains(e.relatedTarget) && !this.$refs.dropdown.contains(e.relatedTarget)) {
              toggle()
            }
          }
          this.$refs.dropdown.addEventListener('mouseout', this.dropdownMouseOutHandler)
        }

        return
      }

      // Leaving when opened by hover
      if (evt.type === 'mouseout' && open && !this.dTriggerClicked) {
        if (!this.$refs.dropdown.contains(evt.relatedTarget)) {
          toggle()
        }
      }
    },

    handleDTriggerToggle (open) {
      // Keep a copy of this
      this.menuOpen = open

      // Clear click state whenever the dropdown closes by any reason
      if (!open) {
        this.dTriggerClicked = false
      }
    },
  },
}
