import { getBlokComponentName } from '~/utils/storyblok'

export default {
  methods: {
    getBlokComponentName,
  },
  mounted () {
    // Just use a blunt timeout since currently don't know if there's a convenient callback function for when StoryblokBridge is loaded.
    setTimeout(() => {
      if (!window.StoryblokBridge) {
        return
      }
      const storyblokInstance = new window.StoryblokBridge()

      // Use the input event for instant update of content
      storyblokInstance.on('input', (evt) => {
        if (evt.story.content._uid === this.localBlok._uid) {
          this.localBlok = evt.story.content
        }
      })

      // Use the bridge to listen the events
      storyblokInstance.on(['published', 'change'], (event) => {
        this.$nuxt.$router.go({
          path: this.$nuxt.$router.currentRoute,
          force: true,
        })
      })
    }, 1e3)
  },
}
