//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import clone from 'lodash.clonedeep'
import { meta } from '~/utils/meta'
import blokPage from '~/mixins/blok-page'
import { LAYOUT_HEADER_STYLES, BUILD_COUNTRIES } from '~/utils/constants'
import { storyDataToBlok } from '~/utils/storyblok'

export default {
  mixins: [blokPage],
  props: {
    blok: {
      type: Object,
      default: null,
    },
    sbFetchParams: {
      type: Object,
      default: () => ({}),
    },
    path: {
      type: String,
      default: null
    },
    signupText: {
      type: String,
      default: null
    },
    signupUrl: {
      type: String,
      default: null
    },
    signupAction: {
      type: Function,
      default: null
    },
  },
  data () {
    return {
      isVariant: false,
      blokOriginal: null,
      blokVariant: null,
      experimentActivated: false,
    }
  },
  async fetch () {
    try {
      if (this.blok) {
        this.blokOriginal = clone(this.blok)
      } else {
        // We use `resolve_links=url` for all fetches by default since it really makes sense. Without this, if you update the slugs of your stories, SB may afterwards serve outdated URLs for story links in the rich text fields of the story being fetched. When we use this, the current URLs of story links will also be present. Shouldn't incur much of a performance penalty either.
        const params = { resolve_links: 'url', ...this.sbFetchParams }
        const storyData = await this.$sb.get(`cdn/stories${this.pagePath}`, params)
          .then(x => x.data)
        this.blokOriginal = storyDataToBlok(storyData)
      }

      if (this.blokOriginal.variant_test?.cached_url) {
        this.blokVariant = await this.$sb.get(`cdn/stories/${this.blokOriginal.variant_test?.cached_url}`).then(r => r.data.story.content)
      }
    } catch (e) {
      if (e.response) {
        // eslint-disable-next-line no-console
        console.error(e.response.data)
      } else {
        // eslint-disable-next-line no-console
        console.error(e)
      }

      this.$nuxt.error({ statusCode: 404, message: 'Page not found' })
    }
  },
  head () {
    const headAttrs = meta({
      title: this.currentBlok?.meta?.title,
      description: this.currentBlok?.meta?.description,
      ogImage: this.currentBlok?.meta_image?.filename,
      ogImageText: this.currentBlok?.meta_image_text?.text,
      noindex: this.currentBlok?.hide_from_search_engine
    })

    // Legal hub specific logic: If the page in question has legal hub categories defined, inject them as meta. These are used for easier search index generation.
    if (this.currentBlok) {
      for (const country of BUILD_COUNTRIES) {
        const countryCode = country.countryCode.toLowerCase()
        const articleCategories = this.currentBlok[`article_categories_${countryCode}`]
        if (articleCategories && articleCategories.length) {
          const metaKey = `app:article-categories-${countryCode}`
          headAttrs.meta.push({ hid: metaKey, content: articleCategories.join(' ') })
        }
      }
    }

    return headAttrs
  },
  computed: {
    currentBlok () {
      return this.isVariant ? this.blokVariant : this.blokOriginal
    },
    headerStyle () {
      return LAYOUT_HEADER_STYLES.includes(this.currentBlok?.header_style) ? this.currentBlok?.header_style : LAYOUT_HEADER_STYLES[0]
    },
    simpleHeader () {
      return this.currentBlok?.simple_header ?? false
    },
    simpleFooter () {
      return this.currentBlok?.simple_footer ?? false
    },
    shareTitle () {
      return this.currentBlok?.meta?.title
    },
    pagePath () {
      if (this.path) {
        // We don't expect the "path" parameter, if provided, to include the country code or a leading slash
        return `/${this.$country.current.code}/${this.path}`
      }
      // The route path always starts with "/" and includes a country prefix
      return this.$route.path
    },
    headerCtaTitle () {
      return this.signupText || this.currentBlok?.header_cta_title
    },
    headerCtaLink () {
      return this.signupUrl || this.$sb.parseLink(this.currentBlok?.header_cta_link)
    },
  },
  watch: {
    blok (blok) {
      this.blokOriginal = clone(blok)
    },
    blokVariant (blok) {
      // We have to activate here also because
      // on local environment, the fetch will be called
      // after mounting on client side navigation
      this.loadExperiment()
    }
  },
  mounted () {
    this.loadExperiment()
  },
  methods: {
    loadExperiment () {
      if (!this.blokOriginal || !this.blokVariant || this.experimentActivated || !this.currentBlok?.feature_flag_key) {
        return
      }

      this.$ph.onFeatureFlags(() => {
        this.isVariant = this.$ph.getFeatureFlag(this.blokOriginal.feature_flag_key) === 'test'
      })

      this.experimentActivated = true
    }
  },
  fetchKey (getCounter) {
    return `storyblok-page:${this.$route.params.page}:${getCounter('storyblok-page')}`
  },
}
