import { render, staticRenderFns } from "./RegistrationFormV2.vue?vue&type=template&id=2e72fa5e&"
import script from "./RegistrationFormV2.vue?vue&type=script&lang=js&"
export * from "./RegistrationFormV2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DTAlert: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTAlert/DTAlert.vue').default,IconGoogle: require('/builds/docue/website/components/Icon/IconGoogle.vue').default,DTButton: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTButton/DTButton.vue').default,IconMicrosoft: require('/builds/docue/website/components/Icon/IconMicrosoft.vue').default,Separator: require('/builds/docue/website/components/Separator.vue').default,DTInput: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTInput/DTInput.vue').default,IconLoader: require('/builds/docue/website/components/Icon/IconLoader.vue').default})
