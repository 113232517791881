import { render, staticRenderFns } from "./HeaderCountrySelector.vue?vue&type=template&id=8fab14be&"
import script from "./HeaderCountrySelector.vue?vue&type=script&lang=js&"
export * from "./HeaderCountrySelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SolidChevronDownIcon: require('/builds/docue/website/node_modules/@nuxt-hero-icons/solid/src/components/ChevronDownIcon.vue').default,DTButton: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTButton/DTButton.vue').default,DTDropdownItem: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTDropdown/DTDropdownItem.vue').default,DTDropdown: require('/builds/docue/website/node_modules/@docue/docue-ui/src/components/DTDropdown/DTDropdown.vue').default})
