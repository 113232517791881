//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import qs from 'qs'

export default {
  props: {
    separator: {
      type: Boolean,
      default: true
    },
    return: {
      type: String,
      default: null
    },
    campaignSource: {
      type: String,
      default: null,
    },
    campaignRef: {
      type: String,
      default: null,
    }
  },
  data () {
    const signupUrl = (provider) => {
      const signupQuery = { provider }
      if (this.return) {
        signupQuery.return = this.return
      }

      const authQuery = {
        return: this.$url.signUp(signupQuery),
        signup: true,
        language: this.$country.current.language
      }

      return `${this.$url.internalApi()}/auth/${provider}?${qs.stringify(authQuery)}`
    }

    return {
      GOOGLE_SIGNUP_URL: signupUrl('google'),
      MICROSOFT_SIGNUP_URL: signupUrl('microsoft'),
      email: '',
      loading: false,
      error: null,
      useEmail: false
    }
  },
  methods: {
    async submit () {
      if (!this.useEmail) {
        this.useEmail = true
        this.$nextTick((_) => {
          this.$refs.email.focusInput()
        })
        return
      }

      if (!this.email) {
        return
      }

      this.track('email')

      this.loading = true

      try {
        const returnParams = ['return', this.return]
        await this.$api.v2.register(
          Object.fromEntries([['email', this.email], returnParams].filter(([attr, value]) => !!value))
        )
        location.assign(
          this.$url.signUpVerify(
            Object.fromEntries([returnParams].filter(([attr, value]) => !!value))
          )
        )
      } catch (e) {
        this.error = this.$t('error.unknown')
      } finally {
        this.loading = false
      }
    },
    track (method) {
      // Store campaign parameters if not already stored
      if (this.campaignSource && this.campaignRef && !this.$cookies.get('campaign_parameters')) {
        this.$cookies.set('campaign_parameters', JSON.stringify({
          source: this.campaignSource,
          ref: this.campaignRef,
        }), {
          path: '/',
          domain: this.$config.cookie.domain,
        })
      }

      this.$gtm.push({
        event: 'choose_signup_method',
        auth_method: method,
      })

      this.$ph.capture('signup_method_choosed', {
        auth_method: method
      })
    },
  }
}
