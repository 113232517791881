//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    simple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      blok: null,
      navItems: [],
      socialMediaItems: []
    }
  },
  fetchKey: 'footer-section',
  async fetch () {
    try {
      const country = this.$country.current
      const story = await this.$sb.get(`cdn/stories/layout-${country.code}`, {
        resolve_links: 'url'
      }).then(r => r.data.story)

      this.blok = story.content

      this.navItems = story.content.footer_nav_items.map(item => ({
        id: item._uid,
        title: item.title,
        subnav_items: (item.subnav_items || []).map(subItem => ({
          id: subItem._uid,
          title: subItem.title,
          url: this.$sb.parseLink(subItem.link),
        }))
      }))

      this.socialMediaItems = story.content.social_media_items.map(item => ({
        id: item._uid,
        network: item.social_media_icon,
        url: item.social_media_url.url
      }))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
}
