//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import throttle from 'lodash.throttle'
import { LAYOUT_HEADER_STYLE_BEIGE, LAYOUT_HEADER_STYLE_BLACK, LAYOUT_HEADER_STYLE_DEEP_BLACK, LAYOUT_HEADER_STYLE_WHITE, LAYOUT_HEADER_STYLE_LIGHTCREAM, LAYOUT_HEADER_STYLE_BLUE } from '~/utils/constants'

export default {
  props: {
    headerStyle: {
      type: String,
      required: true
    },
    simple: {
      type: Boolean,
      default: false
    },
    signupText: {
      type: String,
      default: null
    },
    signupUrl: {
      type: String,
      default: null
    },
    signupAction: {
      type: Function,
      default: null
    },
    signupVariant: {
      type: String,
      default: 'red'
    },
    // Used to be used for ShareButton, currently unused
    shareTitle: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      blok: null,
      navItems: [],
      mobileNavItems: [],
      upperNavItems: [],
      scrolled: false,
      showMobileMenu: false,
      showSignup: false,
      bodyObserver: null,
      headerTop: '0px',
    }
  },
  fetchKey: 'header-section',
  async fetch () {
    try {
      const country = this.$country.current
      const story = await this.$sb.get(`cdn/stories/layout-${country.code}`, {
        resolve_links: 'url'
      }).then(r => r.data.story)

      this.blok = story.content

      this.navItems = (story.content.nav_items || []).map(item => ({
        id: item._uid,
        title: item.title,
        shortTitle: item.short_title,
        url: this.$sb.parseLink(item.link),
        subnav_items: (item.subnav_items || []).map(subItem => ({
          id: subItem._uid,
          title: subItem.title,
          url: this.$sb.parseLink(subItem.link),
          description: subItem.description,
          icon: this.$sb.parseImage(subItem.icon),
        }))
      }))

      this.mobileNavItems = (story.content.mobile_nav_items || []).map(item => ({
        id: item._uid,
        title: item.title,
        url: this.$sb.parseLink(item.link),
        subnav_items: (item.subnav_items || []).map(subItem => ({
          id: subItem._uid,
          title: subItem.title,
          url: this.$sb.parseLink(subItem.link),
          description: subItem.description,
          icon: this.$sb.parseImage(subItem.icon),
        }))
      }))

      this.upperNavItems = (story.content.upper_nav_items || []).map(item => ({
        id: item._uid,
        title: item.title,
        url: this.$sb.parseLink(item.link),
      }))
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  },
  computed: {
    lightContent () {
      return [LAYOUT_HEADER_STYLE_BLACK, LAYOUT_HEADER_STYLE_DEEP_BLACK, LAYOUT_HEADER_STYLE_BLUE].includes(this.headerStyle)
    },
    showLightVariant () {
      return this.lightContent && !this.scrolled
    },
    shareClass () {
      const cls = []

      switch (this.headerStyle) {
        case LAYOUT_HEADER_STYLE_WHITE:
          cls.push('tw-bg-white', 'tw-border-gray-200', 'tw-text-gray-800')
          break
        case LAYOUT_HEADER_STYLE_BLACK:
          cls.push('tw-bg-gray-800', 'tw-border-gray-700', 'tw-text-gray-200')
          break
        case LAYOUT_HEADER_STYLE_DEEP_BLACK:
          cls.push('tw-bg-black', 'tw-border-gray-700', 'tw-text-gray-200')
          break
        case LAYOUT_HEADER_STYLE_BEIGE:
          cls.push('tw-bg-cream-50', 'tw-border-latte-300', 'tw-text-gray-800')
          break
        case LAYOUT_HEADER_STYLE_BLUE:
          cls.push('tw-bg-blue-600', 'tw-border-blue-700/50', 'tw-text-white')
          break
        // TODO, why so?
        case LAYOUT_HEADER_STYLE_LIGHTCREAM:
          cls.push('tw-hidden')
          break
      }

      return cls
    },
    headerClass () {
      let cls = []

      switch (this.headerStyle) {
        case LAYOUT_HEADER_STYLE_WHITE:
          cls.push('tw-bg-white')
          break
        case LAYOUT_HEADER_STYLE_BLACK:
          cls.push('tw-bg-gray-800')
          break
        case LAYOUT_HEADER_STYLE_DEEP_BLACK:
          cls.push('tw-bg-black')
          break
        case LAYOUT_HEADER_STYLE_BEIGE:
          cls.push('tw-bg-cream-50')
          break
        case LAYOUT_HEADER_STYLE_BLUE:
          cls.push('tw-bg-blue-600')
          break
        case LAYOUT_HEADER_STYLE_LIGHTCREAM:
          cls.push('!tw-bg-cream-25')
          break
      }

      if (this.scrolled) {
        cls = ['tw-shadow-md tw-bg-white tw-text-gray-800']
      }

      return cls
    },
  },
  beforeDestroy () {
    if (this.bodyObserver) {
      this.bodyObserver.disconnect()
      this.bodyObserver = null
    }
  },
  mounted () {
    this.handleScroll()

    if (!this.debouncedScrollHandler) {
      this.debouncedScrollHandler = throttle(this.handleScroll, 200)
      window.addEventListener('scroll', this.debouncedScrollHandler)
    }

    /**
     * Support for Intercom banners. Intercom adds styles to body
     * when banner is added, so we listen for that and append top
     * property to to our header to make sticky work correctly.
     */
    if (!this.bodyObserver) {
      this.bodyObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === 'style') {
            this.headerTop = mutation.target.style['margin-top'] || '0px'
          }
        })
      })
      this.bodyObserver.observe(document.body, {
        attributes: true,
      })

      this.headerTop = document.body.style['margin-top'] || '0px'
    }
  },
  methods: {
    handleScroll () {
      const currentScrollPosition = window.pageYOffset
      this.scrolled = currentScrollPosition > 20
    },
    handleSignup (e) {
      if (this.signupAction) {
        e.preventDefault()
        this.signupAction(e)
        return
      }

      if (this.signupUrl) {
        return
      }

      e.preventDefault()
      this.showSignup = true
    },
  }
}
