//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

  props: {
    text: {
      type: String,
      default: null,
    },
    textClass: {
      type: String,
      default: null
    }
  },

}
